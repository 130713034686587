import { useState } from "react";

import axios from "axios";

import { FileChooser } from "../../file/FileChooser";

import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from "../../confirmation/ConfirmDialog";

import useToken from '../../authentication/useToken';

import "./BrandDetails.css";

async function saveImage(file) {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('fileName', file.name);
    formData.append('contentType', file.type);

    const config = {
        headers: {
            'content-type': 'multipart/form-data',
        },
    };

    return axios.post('/storage', formData, config)
        .then(response => response?.data);
}

async function deleteImage(storageId) {
    return axios.delete(`/storage/${storageId}`)
        .then(response => response?.data);
}

export default function BrandDetails({ logoImageId, setLogoImageId, homeImageId, setHomeImageId, onSaveImage, onDeleteImage }) {

    const [confirmLogoImageOpen, setConfirmLogoImageOpen] = useState(false);
    const [confirmHomeImageOpen, setConfirmHomeImageOpen] = useState(false);

    const {token, setToken} = useToken();

    const handleUploadLogoImage = async (file) => {
        !!logoImageId && deleteImage(logoImageId);

        const storageId = await saveImage(file);
        logoImageId = storageId;
        setLogoImageId(storageId);

        !!onSaveImage && onSaveImage({
            logoImageId,
            homeImageId
        });
    }

    const handleUploadHomeImage = async (file) => {
        !!homeImageId && deleteImage(homeImageId);

        const storageId = await saveImage(file);
        homeImageId = storageId;
        setHomeImageId(storageId);

        !!onSaveImage && onSaveImage({
            logoImageId,
            homeImageId
        });
    }

    const handleDeleteLogoImage = async () => {
        await deleteImage(logoImageId)
            .then(() => {
                logoImageId = '';
                setLogoImageId('');
                !!onDeleteImage && onDeleteImage({
                    logoImageId,
                    homeImageId
                });
            });
    }

    const handleDeleteHomeImage = async () => {
        await deleteImage(homeImageId)
            .then(() => {
                homeImageId = '';
                setHomeImageId('');
                !!onDeleteImage && onDeleteImage({
                    logoImageId,
                    homeImageId
                });
            });
    }

    return (<>
        {JSON.stringify(token.email)}
        <InputLabel className="upload-label">
            <div>Logo (256x256 png)</div>
            <FileChooser
                id="logo-image-id"
                label="Upload"
                onFileSelect={file => handleUploadLogoImage(file)} />
            {!!logoImageId && <Button
                id="delete-logo-image"
                aria-label="delete"
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => setConfirmLogoImageOpen(true)}>Delete
            </Button>}
            <ConfirmDialog
                title="Delete Logo?"
                open={confirmLogoImageOpen}
                setOpen={setConfirmLogoImageOpen}
                onConfirm={() => handleDeleteLogoImage()}>
                Are you sure you want to delete the logo image?
            </ConfirmDialog>
        </InputLabel>
        {!!logoImageId && <img src={`/storage/${token.email}/${logoImageId}`} width={256} height={256} alt="Logo"></img>}
        <InputLabel className="upload-label">
            <div>Home (844x256 png)</div>
            <FileChooser
                id="home-image-id"
                label="Upload"
                onFileSelect={file => handleUploadHomeImage(file)} />
            {!!homeImageId && <Button
                aria-label="delete"
                id="delete-home-image"
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => setConfirmHomeImageOpen(true)}>Delete
            </Button>}
            <ConfirmDialog
                title="Delete Home?"
                open={confirmHomeImageOpen}
                setOpen={setConfirmHomeImageOpen}
                onConfirm={() => handleDeleteHomeImage()}>
                Are you sure you want to delete the home image?
            </ConfirmDialog>
        </InputLabel>
        {!!homeImageId && <img src={`/storage/${token.email}/${homeImageId}`} width={844} height={256} alt="Home"></img>}
    </>);
}